import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import './css/index.css'
import axiosPlugin from './plugins/axiosPlugin'
import elPlusPlugin from './plugins/elPlusPlugin'
import utilsPlugin from './plugins/utilsPlugin'
import globalComponents from './plugins/globalComponents'
import vuexPlugin from './plugins/vuexPlugin'
import routerInitPlugin from '@/router/routerInitPlugin'

const head = createHead()
const Vue = createApp(App)
Vue.use(routerInitPlugin)
Vue.use(head)
Vue.use(axiosPlugin)
Vue.use(elPlusPlugin)
Vue.use(utilsPlugin)
Vue.use(globalComponents)
Vue.use(vuexPlugin)
Vue.mount('#app')
