import { useWindowSize } from '@vueuse/core'
import { isMobile } from 'mobile-device-detect'
import pkgLodash from 'lodash'
import screensSettings from '@/settings/screens.json'

const { cloneDeep } = pkgLodash

type ScreensSettingsKeys = keyof typeof screensSettings
type Screens = Record<ScreensSettingsKeys, { size: number, alLeast: boolean }>

export {
  isString as isStringGuard,
  setBasicSeoData as setBasicSeoDataHelper,
  replaceTemplate as replaceTemplateHelper
} from '@/router/routerInitPlugin'

const { width: windowWidth } = useWindowSize()

const screens = computed(() => {
  return Object.fromEntries(
    Object.entries(screensSettings).map(([screen, size]) => {
      return [screen, { size, alLeast: windowWidth.value >= size }]
    })
  ) as Screens
})

const isDesktop = computed(() => {
  return screens.value.big.alLeast
})
const forceMobile = ref(false)
const isMobileDevice = computed(() => {
  return isMobile || (forceMobile.value && !isDesktop.value)
})
function toHumanNumber(val: number | string, maxVal = 100_000, divider = 1_000, symbol = 'k') {
  const numberVal = typeof val === 'string' ? Number.parseFloat(val) : val
  return numberVal >= maxVal ? (numberVal / divider)?.toFixed(1)?.replace('.0', '') + symbol : String(val)
}

function numberFormat(value: number, opt = {}) {
  const options = {
    locale: undefined,
    precision: 2,
    fixed: false,
    shortcuts: false,
    ...opt
  }
  let numberValue = value
  let shortCut = ''
  if (options.shortcuts) {
    if (numberValue >= 1000) {
      if (numberValue >= 1000000000) {
        numberValue = Math.round((numberValue / 1000000000) * 100) / 100
        shortCut = 'G'
      } else if (numberValue >= 1000000) {
        numberValue = Math.round((numberValue / 1000000) * 100) / 100
        shortCut = 'M'
      } else {
        numberValue = Math.round((numberValue / 1000) * 100) / 100
        shortCut = 'k'
      }
    }
  }
  return (
    Intl.NumberFormat(options.locale, {
      minimumFractionDigits: options.fixed ? options.precision : 0,
      maximumFractionDigits: options.precision
    }).format(numberValue ?? 0) + shortCut
  )
}

const tools = {
  windowWidth,
  screens,
  isDesktop,
  forceMobile,
  isMobileDevice,
  isMobile,
  toHumanNumber,
  numberFormat,
  cloneDeep
}

export { tools }
